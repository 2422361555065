.text-box-id {
    border-radius: 6px;
    width: 100%;
    color: black;
    background-color: lightgray;
    text-align: left;
    align-items: left;
    word-wrap: inherit;
    align-content: left;
    display: inline-table;
    margin: auto;
}

.text-box-row {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
}

.center {
    align-items: center;
    text-align: center;
    align-items: center;
}

.left {
    text-align: left;
    align-items: left;
}

.text-box-text {
    text-align: left;
    word-break: break-all;
    width: 100%;
    /* overflow: auto; */
    /* margin: auto; */
    /* overflow:auto;  */
}

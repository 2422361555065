.tile {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 6px;
    max-width: 100%;
}

.center {
    align-items: center;
    text-align: center;
    align-items: center;
}

.left {
    text-align: left;
    align-items: left;
}

.white {
    color: black;
    background-color: white;
}

.gray {
    color: black;
    background-color: lightgray;
}

.black {
    color: white;
    background-color: black;
}
.image-input-label {
    height: 200px;
    width: 200px;
    border-radius: 50%;

    position: relative;
    background-color: white;
    border: none;
    color: black;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
    border-color: black;
    border-width: 1px;
    border-style: solid;
    display: inline-flex;
    vertical-align: auto;
    align-items: center;
    align-content: center;
    text-align: center;
    position: relative;
}

.image-input {
    display: none;
    margin: auto;
}

.image-input-title {
    margin: auto;
    color: black;
    font-weight: bold;
    text-align: center;
}